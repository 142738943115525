


























































































.icon-group {
  position: relative;
  .icon-preview {
    cursor: pointer;
    height: 39px;
    width: 39px;
    border: 1px solid #e2e7f1;
    border-radius: 0.3rem;
    position: relative;
    .icon {
      top: 7px;
      left: 8px;
      font-size: 18px;
    }
  }
}

.icons-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  .overlay {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }
  .icons-box {
    position: absolute;
    background: #fff;
    border: 1px solid #e2e7f1;
    border-radius: 5px;
    padding: 10px;
    max-width: 235px;
    i {
      width: 26px;
      height: 26px;
      display: inline-block;
      font-size: 20px;
      margin: 8px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
